import { Box, Center, Heading, Image, ListItem, OrderedList } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { PageProps } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React, { useEffect, useRef } from 'react';
import customTheme from '../@chakra-ui/gatsby-plugin/theme';
import Layout from '../components/layout';
import { PLATFORMS } from '../content/platforms';
import { detectPlatform } from '../utils/detect-platform';
import { getPageHeight } from '../utils/getPageHeight';

enum Breakpoints {
  TOP,
  HALF,
  THREE_QUARTERS,
  FULL
}

const faqLinkStyles = {
  textDecoration: 'underline'
};
const faqUrl = 'https://xpointtechnologies.zendesk.com/hc/en-us/articles/6274435676177-Geolocation-FAQ';

const IndexPage = ({ location }: PageProps): ReactJSXElement => {
  const platformName = detectPlatform(location.search);
  const platformConfig = PLATFORMS.find(({ id }) => id === platformName);
  const mixpanel = useMixpanel();
  const lastBreakpoint = useRef(0);

  useEffect(() => {
    mixpanel.track('Landing page');

    const pageHeight = getPageHeight();
    const height25percent = pageHeight * 0.25;
    const height50percent = pageHeight * 0.5;
    const height75percent = pageHeight * 0.75;
    const height95percent = pageHeight * 0.95;

    window.addEventListener('scroll', () => {
      const isScrolledTop = window.scrollY < height25percent && lastBreakpoint.current !== Breakpoints.TOP;

      const isScrolledHalf =
        window.scrollY > height50percent &&
        window.scrollY < height75percent &&
        lastBreakpoint.current !== Breakpoints.HALF;

      const isScrolledThreeQuarters =
        window.scrollY > height75percent &&
        window.scrollY < height95percent &&
        lastBreakpoint.current !== Breakpoints.THREE_QUARTERS;

      const isScrolledFull = window.scrollY > height95percent && lastBreakpoint.current !== Breakpoints.FULL;

      if (isScrolledTop) {
        lastBreakpoint.current = Breakpoints.TOP;
        mixpanel.track('Scroll', {
          position: '0%'
        });
      }
      if (isScrolledHalf) {
        lastBreakpoint.current = Breakpoints.HALF;
        mixpanel.track('Scroll', {
          position: '50%'
        });
      }
      if (isScrolledThreeQuarters) {
        lastBreakpoint.current = Breakpoints.THREE_QUARTERS;
        mixpanel.track('Scroll', {
          position: '75%'
        });
      }
      if (isScrolledFull) {
        lastBreakpoint.current = Breakpoints.FULL;
        mixpanel.track('Scroll', {
          position: '95%'
        });
      }
    });
  }, []);

  return (
    typeof window !== 'undefined' && (
      <Layout platformName={platformName}>
        <Center>
          <Box w="100%" maxW="1020px" mt={14} mb={14}>
            <Heading as="h2" mb={6} fontWeight={700}>
              Need Help?
            </Heading>
            <Box>
              {'For detailed installation steps, guides, and more, visit our '}
              <a style={faqLinkStyles} target={'_blank'} rel="noreferrer" href={faqUrl}>
                {'Troubleshooting page.'}
              </a>
            </Box>
            {platformConfig.installationSteps.length > 0 && (
              <Box css={{ marginTop: '48px' }}>
                <Heading as="h2" mb={6} fontWeight={700}>
                  Installation
                </Heading>
                <OrderedList spacing={6} stylePosition="inside" margin={0}>
                  {platformConfig.installationSteps.map(step => (
                    <ListItem
                      key={step.image}
                      css={{
                        borderBottom: `1px solid ${customTheme.colors.border}`,
                        paddingBottom: '32px',
                        '&:last-child': {
                          borderBottom: '0',
                          paddingBottom: ''
                        }
                      }}
                    >
                      {step.text}
                      {step.images && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'baseline',
                            justifyContent: 'flex-start',
                            gap: '16px'
                          }}
                        >
                          {step.images.map(({ src, width }) => (
                            <Image
                              key={src}
                              htmlWidth={width}
                              src={src}
                              mt={6}
                              boxShadow="0px 8px 24px rgba(0, 0, 0, 0.06)"
                            />
                          ))}
                        </div>
                      )}
                      {step.image && (
                        <Image
                          htmlWidth={step.imageWidth}
                          src={step.image}
                          mt={6}
                          boxShadow="0px 8px 24px rgba(0, 0, 0, 0.06)"
                        />
                      )}
                    </ListItem>
                  ))}
                </OrderedList>
              </Box>
            )}
          </Box>
        </Center>
      </Layout>
    )
  );
};

export default IndexPage;
