import { Box, Center, Stack } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

const eulaLinkStyles = {
  textDecoration: 'underline'
};
const eulaUrl = 'https://xpoint.tech/eula';

const Footer = (): ReactJSXElement => (
  <Box fontWeight={300} fontSize="sm" lineHeight={5} bgColor="footerBg" color="white" pt={8} pb={8} pl={5} pr={5}>
    <Center>
      <Stack
        maxW="1020px"
        justify={['flex-start', 'flex-start', 'flex-start']}
        align="start"
        direction={['column', 'column', 'column']}
        spacing={[6, 6, 4]}
        wrap="wrap"
        w={'100%'}
      >
        <img src="../../images/xpoint-logo.svg" alt="xpoint" />
        <Box maxW="888px" textAlign={['left', 'left', 'left']} pb={3}>
          {'By downloading Xpoint Verify you are accepting our '}
          <a style={eulaLinkStyles} target={'_blank'} rel="noreferrer" href={eulaUrl}>
            {'End User License Agreement'}
          </a>
        </Box>
        <Center fontSize="xs" marginTop={5}>
          © {new Date().getFullYear()} Xpoint. All rights reserved
        </Center>
      </Stack>
    </Center>
  </Box>
);

export default Footer;
