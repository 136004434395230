export interface InstallationStep {
  text: string;
  image?: string;
  images?: { src: string; width: string }[];
  imageWidth?: string;
}

export interface IndexPageVariant {
  id: OS;
  name: string;
  installationSteps: InstallationStep[];
  downloadLink: string;
}

export enum OS {
  ANDROID = 'android',
  IOS = 'ios',
  MAC = 'mac',
  WINDOWS = 'windows'
}

export type DownloadLinkConfig = { [key in OS]: string };

export enum STATE {
  NJ = 'nj',
  ON = 'on'
}
