import React from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Link } from 'gatsby';
import {
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Center,
  Flex,
  Image,
  Heading
} from '@chakra-ui/react';
import { PLATFORMS } from '../content/platforms';
import { APP_DOWNLOADS_URL } from 'gatsby-env-variables';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { OS, STATE } from '../models/indexPageVariant';
import { stateConfigs } from '../content/config';

interface Props {
  title: string;
  platformName: string;
}

const buttonStyle = {
  backgroundColor: 'white',
  color: '#1B365D',
  borderRadius: '50px',
  borderColor: 'white',
  height: '80px',
  boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 12px'
};
const androidBadgeStyle = {
  height: '80px'
};
const iOsBadgeStyle = {
  height: '80px'
};
const otherVersionsButtonStyle = {
  color: 'white',
  height: '20px',
  textDecoration: 'underline',
  padding: 0,
  marginTop: '32px'
};

const Intro = ({ title, platformName }: Props): ReactJSXElement => {
  const mixpanel = useMixpanel();
  let downloadButton;
  const platformConfig = PLATFORMS.find(({ id }) => id === platformName);
  const bgImage = '../../images/bg-default.svg';

  let downloadUrl = `${APP_DOWNLOADS_URL}/${platformConfig.downloadLink}`; // default URL
  // URL overrides per state
  const appBuildState = process.env.APP_BUILD_STATE;
  if (appBuildState === STATE.ON || appBuildState === STATE.NJ) {
    downloadUrl = stateConfigs[appBuildState][platformConfig.id];
  }

  if (platformConfig.id === OS.ANDROID) {
    downloadButton = (
      <a href="https://play.google.com/store/apps/details?id=tech.xpoint.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <Image style={androidBadgeStyle} alt="Get it on Google Play" src="../../images/playstore-download.png" />
      </a>
    );
  } else if (platformConfig.id === OS.IOS) {
    downloadButton = (
      <Image
        src="../../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
        alt="Download on AppStore"
        style={iOsBadgeStyle}
      />
    );
  } else if (platformConfig.id === OS.MAC || platformConfig.id === OS.WINDOWS) {
    downloadButton = (
      <Button
        style={buttonStyle}
        iconSpacing={0}
        leftIcon={
          <Image
            ml="5px"
            mr="5px"
            src={`../../icons/${platformConfig.name}.svg`}
            marginTop={platformConfig.id === OS.MAC ? '-5px' : null}
          />
        }
        rightIcon={<Image ml="5px" mr="5px" src="../../icons/download.svg" />}
        variant="solid"
      >
        Download for {platformConfig.name}
      </Button>
    );
  }

  return (
    <Box
      pt={[6, 6, 10, 10]}
      pb={[12, 10, 12, 6]}
      pl={5}
      pr={5}
      bg={[`center bottom / 1700px auto no-repeat url('../../images/${bgImage}')`]}
      bgSize={'cover'}
    >
      <Center>
        <Flex w="100%" maxW="1020px" direction={['column', 'column', 'row']}>
          <Image
            src="../../images/xpoint-logo.svg"
            alt=""
            display={['block', 'block', 'none']}
            maxW="133px"
            order={1}
            alignSelf="flex-start"
          />
          <Box w={['100%', '100%', '100%']} maxW="1020px" mt="10px" order={[3, 3, 2]} alignSelf="center">
            <Image src="../../images/xpoint-logo.svg" alt="" display={['none', 'none', 'block']} />
            <Heading
              as="h1"
              fontSize={['2.625rem', '2.625rem', '2.625rem', '3rem']}
              lineHeight={['3.125rem', '3.125rem', '3.125rem', '4rem']}
              mt={[0, 0, 12]}
              mb={[5, 5, 6]}
              textAlign={['center', 'center', 'left']}
              color="bg"
              maxW="888px"
            >
              {title}
            </Heading>
            <Stack
              direction={['column', 'column', 'column', 'row']}
              spacing={4}
              align="center"
              justify="flex-start"
              alignItems={['center', 'center', 'center', 'flex-start']}
              minH="52px"
            >
              <Flex direction="column" alignItems="center">
                <a
                  href={downloadUrl}
                  onClick={() => {
                    mixpanel.track('Download click', {
                      platform: platformName,
                      downloadType: platformName === OS.IOS || platformName === OS.ANDROID ? 'link to store' : 'file'
                    });
                  }}
                >
                  {downloadButton}
                </a>
                <Menu>
                  <MenuButton style={otherVersionsButtonStyle} variant="filled" as={Button}>
                    Other versions
                  </MenuButton>
                  <MenuList color="text" fontSize="14px">
                    {platformConfig.name !== 'Mac' && (
                      <Link to="?platform=mac">
                        <MenuItem>Download for Mac</MenuItem>
                      </Link>
                    )}
                    {platformConfig.name !== 'Windows' && (
                      <Link to="?platform=windows">
                        <MenuItem>Download for Windows</MenuItem>
                      </Link>
                    )}
                    {platformConfig.name !== 'Android' && (
                      <Link to="?platform=android">
                        <MenuItem>Download for Android</MenuItem>
                      </Link>
                    )}
                    {platformConfig.name !== 'iOS' && (
                      <Link to="?platform=ios">
                        <MenuItem>Download for iOS</MenuItem>
                      </Link>
                    )}
                  </MenuList>
                </Menu>
              </Flex>
            </Stack>
          </Box>
        </Flex>
      </Center>
    </Box>
  );
};

export default Intro;
