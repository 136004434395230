import { OS } from '../models/indexPageVariant';

const safariUserAgentRegex = /^((?!chrome|android).)*safari/i;

export const detectPlatform = (querystring: string): string => {
  const searchParams = new URLSearchParams(querystring);
  let platformName: string = OS.WINDOWS;

  if (searchParams.get('platform')) {
    platformName = searchParams.get('platform').toLowerCase();
  } else if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    if (/Android/.test(userAgent)) {
      platformName = OS.ANDROID;
    } else if (
      /iPhone/.test(platform) ||
      /iPad/.test(platform) ||
      /iOS/.test(platform) ||
      /(iPad)/.test(userAgent) ||
      (platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
      isMobileSafariBrowser()
    ) {
      platformName = OS.IOS;
    } else if (/Win/.test(platform) || /Windows/.test(userAgent)) {
      platformName = OS.WINDOWS;
    } else if (/Mac/.test(platform)) {
      platformName = OS.MAC;
    }
  }
  return platformName;
};

export const isMobileSafariBrowser = (): boolean => {
  const isMac = window.navigator.userAgent.indexOf('Mac') !== -1 && window.navigator.maxTouchPoints < 1;
  return safariUserAgentRegex.test(navigator.userAgent) && !isMac;
};
