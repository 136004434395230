import { IndexPageVariant, InstallationStep, OS } from '../models/indexPageVariant';

const items: InstallationStep[] = [
  {
    text: 'Launch the XpointVerifyGeolocationInstaller.exe.',
    image: '../../content/windows/win-1.png',
    imageWidth: '598px'
  },
  {
    text: 'The setup will request for administrative privileges. Please click ‘Yes’ to proceed.',
    image: '../../content/windows/win-2.png',
    imageWidth: '398px'
  },
  {
    text: 'The installation will proceed automatically, requiring no further configuration or confirmation steps.',
    image: '../../content/windows/win-3.png',
    imageWidth: '598px'
  },
  {
    text: 'To complete the setup, click ‘Finish’. This will also launch the application.',
    image: '../../content/windows/win-4.png',
    imageWidth: '598px'
  },
  {
    text: 'After installation, the application will run in the background. To reopen, click the Xpoint icon in the system tray.',
    image: '../../content/windows/win-5.png',
    imageWidth: '298px'
  }
];

export const windowsVariant: IndexPageVariant = {
  id: OS.WINDOWS,
  name: 'Windows',
  installationSteps: items,
  downloadLink: 'XpointVerifyGeolocationInstaller.exe'
};
