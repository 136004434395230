import { IndexPageVariant, InstallationStep, OS } from '../models/indexPageVariant';

const items: InstallationStep[] = [
  {
    text: 'Download the XpointVerifyGeolocationInstaller.pkg file and open it by double-clicking.',
    image: '../../content/mac/mac-1.png',
    imageWidth: '600px'
  },
  {
    text: 'In the installer window, click ‘Continue’.',
    image: '../../content/mac/mac-2.png',
    imageWidth: '600px'
  },
  {
    text: `Optionally change the Install Location (typically not required), then click ‘Install’.`,
    image: '../../content/mac/mac-3.png',
    imageWidth: '600px'
  },
  {
    text: `Provide necessary permissions using Touch ID or your password to proceed.`,
    image: '../../content/mac/mac-4.png',
    imageWidth: '280px'
  },
  {
    text: `Once installation finishes, click ‘Close’ to exit the installer.`,
    image: '../../content/mac/mac-5.png',
    imageWidth: '600px'
  },
  {
    text: `The installer may offer to delete itself from the Downloads folder - choose as you wish.`,
    image: '../../content/mac/mac-6.png',
    imageWidth: '600px'
  },
  {
    text: `Xpoint Verify will start automatically after installation and during each macOS restart. Look for the Xpoint logo in the macOS menu bar.`,
    image: '../../content/mac/mac-7.png',
    imageWidth: '150px'
  },
  {
    text: `To manually start Xpoint Verify, search for it in Launchpad or via Spotlight.`,
    image: '../../content/mac/mac-8.png',
    imageWidth: '400px'
  }
];

export const macVariant: IndexPageVariant = {
  id: OS.MAC,
  name: 'Mac',
  installationSteps: items,
  downloadLink: 'XpointVerifyGeolocationInstaller.pkg'
};
