import { Box, Flex, Spacer } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import Footer from './footer';
import Intro from './intro';

interface Props {
  children: ReactJSXElement;
  platformName: string;
}

const Layout = ({ children, platformName }: Props): ReactJSXElement => {
  return (
    <Flex flexDirection={'column'} css={{ height: '100vh' }}>
      <Intro title={'Xpoint Verify: Your Trusted Geolocation Partner for Gaming'} platformName={platformName} />
      <Box pl={5} pr={5}>
        <main>{children}</main>
      </Box>
      <Spacer />
      <Footer />
    </Flex>
  );
};

export default Layout;
